<template>
    <div class="col-12">
        <div class="row">

            <CategoriaProduto v-show="!loading"
                v-for="(item, index) in categorias"
                :key="index"
                :id="item.id"
                :titulo="item.descricao"
                @completado="completado"
            />

            <Loading v-if="loading"/>

        </div>
    </div>
</template>

<script>
import CategoriaProduto from "../components/CategoriaProduto.vue";
import Loading from "../components/Loading.vue";
import axiosApi from '@/axios'


export default {
    components: { CategoriaProduto, Loading },
    name: "Produtos",

    data() {
        return {
            categorias: [],
            total: 0,
            loading: true,
            contador: 0
        };
    },

    created() {

    },

    mounted() {
        axiosApi
            .get('/categorias?ativado=1')
            .then((response) => {
                this.categorias = response.data.itens;
                this.total = this.categorias.length
            });
    },

    methods: {
        completado(){
            this.contador++
            if (this.contador == this.total) {
                this.loading = false
            }
        }
    },
};
</script>
