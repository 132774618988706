<template>
    <div v-if="produtos.length > 0" class="col-12 card-lista">
        <div class="row" v-if="!loading">
            <div class="col-12 mb-4 divisoria">
                <p>
                    {{ titulo }}
                </p>
            </div>

            <div
                v-for="(item, index) in produtos"
                :key="index"
                class="col-12 col-md-4 col-lg-3 mb-4"
            >
                <div class="card p-0">
                    <div>
                        <ImgProduto :img-bg="true" :url-imagem="item.url_midia" />
                        <p>{{ item.descricao }}</p>
                    </div>
                    <router-link
                        :to="{
                            path: item.url_produto,
                            params: { idProduto: item.id },
                        }"
                        class="btn btn-site"
                        >Detalhes</router-link
                    >
                </div>
            </div>

        </div>

    </div>
</template>

<script>
import ImgProduto from "./ImgProduto.vue";
import axiosApi from '@/axios'

export default {
    name: "CategoriaProduto",
    components: { ImgProduto },

    data() {
        return {
            loading: false,
            produtos: [],
        };
    },

    created() {
        this.loading = true;
        axiosApi
            .get(
                `/produtos?categoria=${this.id}&ativado=1`
            )
            .then((response) => {
                this.produtos = response.data.itens;
                this.loading = false;
                this.$emit('completado')
            });
    },

    mounted() {},

    props: {
        id: Number,
        titulo: String,
    },
};
</script>


<style lang="scss">
@import "@/assets/scss/style.scss";
.card-lista {
    .divisoria {
        background-color: $marrom;
        border-radius: 50px;

        p {
            font-family: $roboto;
            font-weight: bold;
            font-style: normal;
            font-size: 18px;
            line-height: 18px;
            color: $branco;
            padding: 10px 10px;
            margin: 0;
        }
    }

    .card {
        background-color: $marrom;
        color: $branco;
        p {
            color: $branco;
            font-family: $roboto;
            font-weight: normal;
            font-style: normal;
            font-size: 16px;
            line-height: 23px;
            padding: 15px 15px 7px;
            min-height: 91px;
        }
        .btn-site {
            color: $marrom;
            background-color: $branco;
            border-radius: 0;
        }
    }
}
</style>
  